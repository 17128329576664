import './loader.css';

export default function Loader(props) {


  return (

  <div  className="loader" >
     <p className="loaderText">{props.text} <div class="loader111"></div></p>
     </div>

     
    )
  }