import 'car-makes-icons/dist/style.css';

const Brands = [
  'all',
  'BMW',
  'Audi',
  'Mercedes',
  'Toyota',
  'Hyundai',
  'Kia',
  'Range Rover',
  'Rolls Royse',
  'Bentley',
  'Mazda',
  'Chevrolet',
  'GMC',
  'Cadillac',
  'Land Rover',
  'Lamborghini',
  'Ferrari',
  'Porsche',
  'Tesla',
  'Bugatti',
  'McLaren',
];

export default Brands;
