import './noMatch.css';
const NoMatch = () => {
  return (
    <div className='nothings'>
      <div className='emptyMessage'>
        Not Found 404
        {/* <Link> 
                    <div className='' >
                        back to home
                        </div>  </Link> */}
      </div>
    </div>
  );
};

export default NoMatch;
