import 'car-makes-icons/dist/style.css';



const Brands = [
    'all', 'silver', 'black', 'white', 'red', 'green', 'orange', 'blue' , 'gray' , 'yellow'

]

export default Brands;
